import { Component, Vue } from 'vue-property-decorator';
import template from './GestionDossier.Template.vue';

@Component({
    ...template,
    name: 'GestionDossier',
})
export default class GestionDossier extends Vue {
}

